/* eslint-disable react-hooks/exhaustive-deps */
import 'keen-slider/keen-slider.min.css';

import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

import { isNumeric } from '@/lib/util';

import styles from './Carousel.module.scss';

enum CarouselType {
  Default = 'default',
  Loop = 'loop'
}

type Props = {
  children?: any;
  rows?: number;
  gap?: string;
  snapAlign?: string;
  className?: string;
  noControls?: boolean;
  noControlsPlaceholder?: boolean;
  isCentered?: boolean;
  type?: CarouselType;
};

const Carousel: React.FC<Props> = ({
  children = [],
  rows = 1,
  gap = '0',
  snapAlign,
  noControls,
  className = '',
  isCentered = false,
  type = CarouselType.Default
}) => {
  const isCarousel = type === CarouselType.Loop;
  const gapValue = gap.replace('px', '');
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slides: {
      origin: 'center',
      perView: 'auto',
      spacing: isNumeric(gapValue) ? Number(gapValue) : 24
    }
  });

  const carouselClick = (e) => {
    const clickTarget = e.target;

    const parentTarget = clickTarget.closest('.keen-slider');
    const clickTargetWidth = parentTarget.offsetWidth;
    const xCoordInClickTarget = e.clientX - parentTarget.getBoundingClientRect().left;
    if (clickTargetWidth / 2 > xCoordInClickTarget) {
      // clicked left
      instanceRef.current?.prev();
    } else {
      // clicked right
      instanceRef.current?.next();
    }
  };

  return (
    <div
      className={clsx(styles.container, className, {
        [styles.slider]: !isCarousel,
        'keen-slider': isCarousel
      })}
      {...(isCarousel && {
        ref: sliderRef,
        onClick: carouselClick
      })}
    >
      {React.Children.map(children, (child, i) => (
        <div
          className={clsx('keen-slider__slide', {
            [styles.slider__block]: !isCarousel,
            [styles.carousel__block]: isCarousel
          })}
          key={child?.key || i}
          style={{ overflow: 'visible' }} // Overriding the overflow style so the cards width won't break the Carousel styling
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default Carousel;
