import Carousel from '@/components/cms/Carousel';

import DynamicComponent from './DynamicComponent';

const CarouselWrapper = ({ blok }) => {
  const { content, rows, gap, noControls, noControlsPlaceholder, isCentered, type } = blok;

  return (
    <Carousel
      rows={rows}
      gap={gap}
      noControls={noControls}
      noControlsPlaceholder={noControlsPlaceholder}
      isCentered={isCentered}
      type={type}
    >
      {content.map((block) => (
        <DynamicComponent key={block._uid} blok={block} />
      ))}
    </Carousel>
  );
};

export default CarouselWrapper;
